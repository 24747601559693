export default {
  vfi18n: {
    useSpecificCharacter: "Use Specific Character",
    title: "Character Creation",
    character: "Character",
    name: "Name",
    description: "Character Description",
    hints: "Hints",
    reverseHints: "Reverse Hints",
    image: "Image",
    confirmCharacter: "Confirm Character",
    generatedImage: "Generated Image",
    selectImage: "Select an Image for the Character",
    generateImage: "Generate Image", // Modified here
    imageGeneration: "Image Generation",
    cameraEffects: "Camera Effects",
    moveLeft: "Move Left",
    moveRight: "Move Right",
    zoomIn: "Zoom In",
    zoomOut: "Zoom Out",
    generatedPicture: "Generated Picture",
    selectBackground: "Select an Image as the Background for the Current Page",
    page: "Page",
    repaginate: "Repaginate",
    composeVideo: "Compose Video",
    picture: "Page",
    addPage: "Add Page",
    copy: "Copy",
    delete: "Delete",
    characterDescription:
      "Describe the character's appearance, for example: xxxx",
    undesiredAppearance:
      "Describe features you do not want to appear on this character. For example, if you want a character with long hair, enter 'short hair' here.",
    desiredElements: "Describe elements you want in the image, story, etc.",
    undesiredElements:
      "Describe elements you do not want in the image. For example, if you want an image of an empty street, you can enter: humans",
    addParagraph: "Add a paragraph of text to this page.",
    confirmVideoType: "Confirm Video Type",
    selectVideoType: "Select Your Video Type",
    visualStyle: "Visual Style",
    videoAspectRatio: "Video Aspect Ratio",
    fillRequiredFields: "Please fill in all required fields first",
    confirmDeleteSegment: "Are you sure you want to delete this segment?",
    segmentDeletionWarning:
      "Deleting a segment is irreversible. Please proceed with caution.",
    confirmRepaginate: "Are you sure you want to repaginate?",
    repaginateWarning:
      "Repaginating will regenerate all page content, and the current edits will not be preserved.",
    videoSynthesisInProgress: "in progress",
    cancel: "Cancel",
    confirm: "Confirm",
    generationComplete: "complete",
    contentGenerationInProgress: "in progress",
    imageGenerating: "Image Generating",
    newVideo: "New Video",
    startCreation: "Start Creation",
    textToFilm: "Text to Film",
    video: "Video",
    draft: "Draft",
    wait: "Wait",
    synthesizing: "Synthesizing",
    synthesisFailed: "Synthesis Failed",
    download: "Download",
    reEdit: "Re-Edit",
    reSynthesize: "Re-Synthesize",
    rename: "Rename",
    confirmScript: "Confirm Script",
    script: "Script",
    eachParagraphGeneratesFrame:
      "Each paragraph will generate an independent storyboard frame",
    enterOrPasteScript: "Enter or paste the script",
    textToScript: "Text to Script",
    rewriteNovelScript: "Rewrite novels, scripts, stories, etc., into script",
    smartStoryboard: "Smart Storyboard",
    autoSegmentationBasedOnScript: "Auto-segmentation based on script content",
    AItranscription: "AI Transcription",
    fillInRequiredContentFirst: "Please fill in the required content first",
    AITranscriptionInProgress: "AI Transcription in Progress",
    smartStoryboardInProgress: "Smart Storyboard in Progress",
    refreshing: "Refreshing",
    username: "User Name",
    password: "Password",
    login: "Login",
    processing: "Processing",
    initializing: "Initializing",

    selectMainCharacterImage: "Select a screen image for the main character",
    ensureConsistency:
      "Ensure consistency between the character names on this page and in the script for accurate AI matching",
    // "character": "Character",
    selectCharacterImage: "Select Character Image",
    replace: "Replace",
    enterCharacterName: "Enter character name",
    addCharacter: "Add Character",
    addNewCharacter: "Add New Character",
    imageLibrary: "Character Image Library",
    all: "All",
    male: "Male",
    female: "Female",
    extractingCharacter: "Character Extracting",
    confirmDeleteCharacter: "Are you sure you want to delete this character?",
    imageNotSelected: "Image not selected",
    nameNotFilled: "Name not filled",
    select: "Select",
    added: "Added",
    remove: "Remove",
    add: "Add",
    AIExtracting: "AI Auto Extracting",
    selectCharacterConsistency:
      "Selecting a character will ensure that the generated character image in the picture is consistent with this character.",
    naturalLanguage:
      "Natural Language: Describe the scene presented in the image in normal language, suitable for beginners.",
    conventional:
      "Conventional: A more precise classic prompt word input mode, separating words with commas, suitable for users with AI drawing foundation and certain prompt word usage skills.",
    oppositeEffect:
      "The opposite effect of prompt words, used to describe elements you do not want to appear in the scene.",
    associativity:
      "The higher the associativity between the generated image and the prompt words, the stronger the association. However, the self-expression and creative extent of AI will decrease accordingly.",
    samplingSteps:
      "The higher the sampling steps, the richer the details, but the generation time of the image will correspondingly increase. Note that excessively high steps may cause detail distortion.",
    imageNumbering:
      "A specific number assigned to each generated image. Using the same number under the same prompt words can reproduce the drawing result.",
    uploadReferenceImages:
      "Upload other images to provide reference and guidance for AI drawing. Multiple reference types can be specified simultaneously.",
    higherResolutionMoreDetails:
      "Generate higher resolution, more detailed images, takes longer time",
    referenceTypeSetting:
      "The reference type setting determines which dimension of reference elements AI extracts from the image.",
    referenceTypeDecision:
      "Reference type setting determines which dimension of reference elements AI extracts from the image",
    manageCharacters: "Manage Characters",
    // "AIExtracting": "AI Extracting",
    highResolutionImages: "High-Resolution Images",
    imageReference: "Image Reference",
    seedValue: "Seed Value",
    samplingStepsLabel: "Sampling Steps",
    promptWordRelevance: "Prompt Word Relevance",
    pageAnimation: "Page Animation",
    noAnimation: "No Animation",
    referenceImage: "Reference Image",
    uploadReferenceImage: "Upload Reference Image",
    imageFormatInfo: "PNG, JPG, or WEBP format, maximum 5MB",
    type: "Type",
    detectPose:
      "Detect the pose of any character in any input image and use it to guide your image generation.",
    referenceIntensity: "Reference Intensity",
    naturalLanguageL: "Natural Language",
    common: "Common",
    creative: "Creative",
    recommendation: "Recommendation",
    precise: "Precise",
    fast: "Fast",
    balanced: "Balanced",
    detailed: "Detailed",
    random: "Random",
    fixed: "Fixed",

    low: "Low",
    normal: "Normal",
    high: "High",
    insideMask: "Inside Mask",
    outsideMask: "Outside Mask",

    original: "Original",
    redraw: "Redraw",
    // cancel: 'Cancel',
    confirmModification: "Confirm Modification",
    // image: 'Image',
    redrawMagnitude: "Redraw Magnitude",
    redrawArea: "Redraw Area",

    specifyFrames: "Specify Frames",

    repaintScope: "The specific area in the image to be modified or repainted",
    repaintIntensity:
      "The intensity of modifications during the image repaint process, ranging from minor adjustments to complete changes",
    selectImageFirst: "Please select an image to generate first",
    weak: "Weak",
    //   "balanced": "Balanced",
    strong: "Strong",
  },
};
