
import Vue from "vue";
import LoadingBar from "./index.vue";
import vuetify from "../../plugins/vuetify";
import i18n from "../../i18n";


const MessageConstruct = Vue.extend(LoadingBar)

/**
 * Message 全局提示类
 * options:Object|String
 *        message:String|VNode  消息内容，支持自定义模板
 *        duration:Int            消息显示的时间间隔，default:3000
 *        offset:Int              消息距离top距离
 */
class Loading {
  static instance;
  constructor(options) {

    if (!Loading.instance) {

      Loading.instance = new MessageConstruct({
        i18n,
        data: options,
        vuetify,
      });
    }

    Loading.instance.$mount();
    document.getElementById("app").appendChild(Loading.instance.$el);
    return Loading.instance;
  }


}






class LoadingFactry {
  show(time, text) {
    new Loading().showLoading(time, text)
  }
  has() {
    return new Loading().hasLoading()
  }
  hide() {
    new Loading().hideLoading()
  }
}


export default new LoadingFactry();
