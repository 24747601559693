// 获取根节点
var root = document.documentElement;

// 设置字体大小的函数
function setRootFontSize() {
  // 获取页面宽高
  var screenWidth = window.innerWidth;
  var screenHeight = window.innerHeight;

  // 计算宽高比
  // var aspectRatio = screenWidth / screenHeight;
  let ah = screenHeight / 940
  let aw = screenWidth / 1920

  // 取宽高比更小的一方作为基准
  var baseSize = Math.min(ah, aw);

  // 计算字体大小，80是一个调整因子，可以根据需要调整
  var fontSize = Math.max(baseSize * 16, 10);


  // 将字体大小应用到根节点
  root.style.fontSize = fontSize + 'px';
}

// 初始化时设置字体大小
setRootFontSize();

// 在窗口大小变化时重新设置字体大小
window.addEventListener('resize', setRootFontSize);