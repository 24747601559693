/**
 * Created by xuyq on 2021/1/19.
 */
import Vue from "vue";
import MessageView from "./index.vue";
import vuetify from "../../plugins/vuetify";
// import { isVNode } from "@myops/core";

let seed = 1;

const MessageConstruct = Vue.extend(MessageView).extend({
  data() {
    return {
      onClose: null,
      message: "",
    };
  },
  props: {
    value: true,
  },
  watch: {
    isActive() {
      if (this.onClose) {
        this.onClose(this.id);
      }
    },
  },
});

/**
 * Message 全局提示类
 * options:Object|String
 *        message:String|VNode  消息内容，支持自定义模板
 *        duration:Int            消息显示的时间间隔，default:3000
 *        offset:Int              消息距离top距离
 */
class Message {
  static instances = [];
  constructor(options) {
    options = options || {};
    if (typeof options === "string") {
      options = {
        message: options,
      };
    }
    this.id = "message_" + seed++;
    options.onClose = () => Message.close(this.id);
    this.instance = new MessageConstruct({
      data: options,
      vuetify,
    });
    // if (isVNode(this.instance.message)) {
    //   this.instance.$slots.default = [this.instance.message];
    //   this.instance.message = null;
    // }
    this.instance.$mount();
    document.getElementById("app").appendChild(this.instance.$el);

    let verticalOffset = options.offset || 20;
    Message.instances.forEach((item) => {
      verticalOffset += item.instance.$el.offsetHeight;
    });
    this.instance.verticalOffset = verticalOffset;
    this.instance.visible = true;
    this.instance.$el.style.zIndex = 2000 + seed;
    Message.instances.push(this);
  }
  static close(id) {
    for (let i = 0; i < Message.instances.length; i++) {
      if (id === Message.instances[i].id) {
        let message = Message.instances[i];
        message.instance.$destroy();
        document.getElementById("app").removeChild(message.instance.$el);
        Message.instances.splice(i, 1);
        break;
      }
    }
  }
}

["success", "warning", "info", "error"].forEach((type) => {
  Message[type] = (options) => {
    if (typeof options === "string") {
      options = {
        message: options,
      };
    }
    options.type = type;
    return new Message(options);
  };
});

export default Message;
