import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  { path: "/", redirect: "/home" },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: '/styleSelection',
    name: 'styleSelection',
    component: () =>
      import(/* webpackChunkName: "styleSelection" */ "../views/StyleSelection.vue"),
  },
  {
    path: "/characterSelection",
    name: "characterSelection",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CharacterSelection.vue"),
  },
  {
    path: "/generateVideo",
    name: "generateVideo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GenerateVideo.vue"),
  },
  {
    path: "/textMontage",
    name: "textMontage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TextMontage" */ "../views/TextMontage.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");

  if (to.name !== "login" && !token) {
    // 如果不是登录页且用户未登录，则跳转到登录页
    next({ path: "/login", query: { redirect: to.fullPath } });
    // } else if (to.name === "login" && token) {
    //   // 如果是登录页且用户已登录，则跳转到首页
    //   next("/");
  } else {
    next();
  }
});

export default router;
