// mockService.js

import Mock from "mockjs";

// 使用 Mock.mock() 设置模拟数据
Mock.mock("/api/getActorList", 'get', {
  data: [
    {
      id: 1,
      name: "张三",
      description: "张三是一个充满神秘色彩的角色，过去的经历鲜为人知。",
    },
    {
      id: 2,
      name: "李四",
      description: "李四是一位充满冒险精神的灵魂，总是寻求新的挑战。",
    },
    {
      id: 3,
      name: "王五",
      description: "王五是一位热衷于编码和构建事物的科技爱好者。",
    },
    {
      id: 4,
      name: "赵六",
      description: "赵六是一位热爱绘画和创意的艺术家。",
    },
    {
      id: 5,
      name: "钱七",
      description: "钱七是一位致力于做出突破性发现的科学家。",
    },
  ],
});

Mock.mock("/api/generateAiImage", 'post', [{
  id: '@id',
  img: '@integer(0,9)'
}]);

Mock.mock("/api/generateStock", "post", {
  id: "@id",
  name: "@name",
  description: "@cparagraph",
  stockImgList: [{ id: "@id", img: "@integer(1,9)" }],
  img: "@integer(1,9)",
});



