import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "./locales/en";
import zh from "./locales/zh_CN";

Vue.use(VueI18n);

const messages = {
  en,
  zh,
};

// 获取用户浏览器的语言
// const userLanguage = window.navigator.language.split("-")[0];

export default new VueI18n({
  // locale: userLanguage || "zh",
  locale: "zh",
  messages,
});
