<template>
  <v-sheet color="#5700ff" class="rounded-lg pl-1 subtitle-2 font-weight-bold">
    <v-sheet color="#2D2D2D" class="rounded-lg px-2 py-2">
      <slot></slot>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped></style>