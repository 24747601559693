export default {
  vfi18n: {
    useSpecificCharacter: "使用特定角色",
    title: "形象创造",
    character: "角色",
    name: "名字",
    description: "人物介绍",
    hints: "提示词",
    reverseHints: "反向提示词",
    image: "图片",
    confirmCharacter: "确认角色形象",
    generatedImage: "生成的形象",
    selectImage: "选择一张图片作为当前选中角色的形象",
    generateImage: "生成图片", // 修改这里
    imageGeneration: "图片生成",
    cameraEffects: "镜头效果",
    moveLeft: "左移",
    moveRight: "右移",
    zoomIn: "放大",
    zoomOut: "缩小",
    generatedPicture: "生成的图片",
    selectBackground: "选择一张图片作为当前页的背景图",
    page: "页",
    repaginate: "重新分页",
    composeVideo: "合成视频",
    picture: "张",
    addPage: "添加页面",
    copy: "复制",
    delete: "删除",
    characterDescription: "描述角色的外形，例如：xxxx",
    undesiredAppearance:
      "描述你不希望在这名角色身上出现的外形特征，例如希望生成长发角色，则在此输入“短发”",
    desiredElements:
      "描述你图片中的元素，如需生成指定角色的图片，请在角色列表中添加",
    undesiredElements:
      "描述你不希望在图片中的元素，例如你想要一张描绘空无一人街道的图片，可以输入：人类",
    addParagraph: "为此页面添加一段文本",
    confirmVideoType: "确认视频类型",
    selectVideoType: "选择你的视频类型",
    visualStyle: "视觉风格",
    videoAspectRatio: "视频比例",
    fillRequiredFields: "请先填写完必要的内容",
    confirmDeleteSegment: "确定要删除该片段吗？",
    segmentDeletionWarning: "片段删除后不可恢复，请谨慎操作",
    confirmRepaginate: "确定要重新分页吗？",
    repaginateWarning: "重新生成全部页面内容，当前编辑的内容将不会被保留",
    videoSynthesisInProgress: "视频合成中",
    cancel: "取消",
    confirm: "确认",
    generationComplete: "完毕",
    contentGenerationInProgress: "内容生成中",
    imageGenerating: "图片生成中",
    newVideo: "新视频",
    startCreation: "开始创作",
    textToFilm: "文字成片",
    video: "视频",
    draft: "草稿",
    wait: "排队中",
    synthesizing: "合成中",
    synthesisFailed: "合成失败",
    download: "下载",
    reEdit: "再编辑",
    reSynthesize: "重新合成",
    rename: "重命名",
    confirmScript: "继续",
    script: "解说文案",
    eachParagraphGeneratesFrame: "每个段落将生成一个独立的分镜画面",
    enterOrPasteScript: "请输入或粘贴文本内容",
    textToScript: "文本转解说",
    rewriteNovelScript: "将小说、剧本、故事等内容改写为解说文案",
    smartStoryboard: "智能分镜",
    autoSegmentationBasedOnScript: "根据解说文案内容自动完成分段",
    AItranscription: "AI转写",
    fillInRequiredContentFirst: "请先填写完必要的内容",
    AITranscriptionInProgress: "AI转写中",
    smartStoryboardInProgress: "智能分镜中",
    refreshing: "刷新中",
    username: "用户名",
    password: "密码",
    login: "登录",
    processing: "处理中",
    initializing: "初始化中",

    selectMainCharacterImage: "为主要角色选定一个银幕形象",
    ensureConsistency:
      "请确保本页面的角色名称和解说文案中保持一致，便于AI精准匹配",
    // "character": "角色",
    selectCharacterImage: "选择角色形象",
    replace: "替换",
    enterCharacterName: "输入角色名称",
    addCharacter: "请添加角色",
    addNewCharacter: "添加新角色",
    imageLibrary: "形象库",
    all: "全部",
    male: "男",
    female: "女",
    extractingCharacter: "角色提取中",
    confirmDeleteCharacter: "确定要删除该角色吗？",
    imageNotSelected: "形象未选择",
    nameNotFilled: "名称未填写",
    select: "选择",
    added: "已添加",
    remove: "移除",
    add: "添加",

    AIExtracting: "AI自动提取",
    selectCharacterConsistency:
      "勾选某个角色后，生成图片中的人物形象将和此角色保持一致",
    naturalLanguage: "自然语言：用正常的语言描述图像呈现的画面，适合新手",
    conventional:
      "常规：更加精确的经典提示词输入模式，用逗号分隔词组，适合有AI绘画基础、掌握一定提示词使用技巧的用户",
    oppositeEffect: "和提示词起相反的作用，用于描述你不希望在画面中出现的元素",
    associativity:
      "生成的图片和提示词的关联度，越高关联度越强，同时AI的自我发挥与创意幅度也会随之降低",
    samplingSteps:
      "采样步数越高，细节越丰富，但生成图片的时间也会相应变长，注意过高的步数可能引发细节扭曲",
    imageNumbering:
      "每次生成图片的特定编号，提示词相同的情况下，使用同一个编号可复原绘画结果",
    higherResolutionMoreDetails: "生成更高分辨率，更丰富细节的图片，耗时更长",
    uploadReferenceImages:
      "上传其他图片来为AI绘图提供参考和指导，可同时指定多个参考类型",
    referenceTypeSetting:
      "参考类型设置决定了AI从图像中提取哪一个维度的参考元素",
    referenceTypeDecision:
      "参考类型设置决定了AI从图像中提取哪一个维度的参考元素",
    manageCharacters: "管理角色",
    // "AIExtracting": "AI自动提取",
    highResolutionImages: "高清图像",
    imageReference: "图像参考",
    seedValue: "种子值",
    samplingStepsLabel: "采样步数",
    promptWordRelevance: "提示词相关性",
    pageAnimation: "页面动效",
    noAnimation: "无动效",
    referenceImage: "参考图",
    uploadReferenceImage: "上传参考图片",
    imageFormatInfo: "PNG、JPG 或 WEBP 格式，最大5MB",
    type: "类型",
    detectPose: "检测任何输入图像中任何角色的姿势，并使用它来指导您的图像生成",
    referenceIntensity: "参考强度",
    naturalLanguageL: "自然语言",
    common: "常规",
    creative: "创意",
    recommendation: "推荐",
    precise: "精确",
    fast: "快速",
    balanced: "均衡",
    detailed: "细致",
    random: "随机",
    fixed: "固定",

    low: "低",
    normal: "常规",
    high: "高",
    insideMask: "蒙版内",
    outsideMask: "蒙版外",

    original: "原图",
    redraw: "重绘",
    // cancel: '取消',
    confirmModification: "确认修改",
    // image: '图片',
    redrawMagnitude: "重绘幅度",
    redrawArea: "重绘区域",
    specifyFrames: "指定分镜数量",

    repaintScope: "图像中将要进行修改或重绘操作的具体区域",
    repaintIntensity: "图像在重绘过程中修改的强度，从轻微调整到彻底改变",
    selectImageFirst: "请先选择生成图片",
    weak: "弱",
    //   "balanced": "均衡",
    strong: "强",
  },
};
