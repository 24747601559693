/**
 * Created by xuyq on 2021/1/19.
 */
import Vue from "vue";
import ConfirmBox from "./index.vue";
import vuetify from "../../plugins/vuetify";
// import { isVNode } from "@myops/core";


const ConfirmConstruct = Vue.extend(ConfirmBox)

const Confirm = function (message) {
  console.log(this)
  const instance = new ConfirmConstruct({
    i18n: this.$i18n,
    vuetify,
  })

  instance.$mount()

  return instance.showConfirmDialog(message)
}

export default Confirm


