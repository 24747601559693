// axios.js

import axios from 'axios';
import router from '@/router'; // 如果你使用了Vue Router，确保引入正确的router对象
import Message from "../components/Message";

const instance = axios.create({
  baseURL: '/',  // 根据实际情况进行调整
  timeout: 60000,  // 请求超时时间
});

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 在请求发送之前可以做一些处理，例如设置请求头
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
    return config;
  },
  error => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    // 在接收响应数据之前可以做一些处理
    return response;
  },
  error => {
    // 处理响应错误
    if (error.response) {
      // 如果服务器返回错误状态码
      if (error.response.status === 401) {
        // 未授权，跳转到登录页面
        router.push('/login');
      }
    } else if (error.request) {
      // 如果请求未发出
      Message.error('状态异常，请稍后重试')
      console.error('请求未发出:', error.request);
    } else {
      // 其他错误
      Message.error('状态异常，请稍后重试')
      console.error('Error:');
    }

    return Promise.reject(error);
  }
);

export default instance;
