<template>
  <div class="message-view" :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'" :style="positionStyle">
    <transition name="el-message-fade">
      <v-alert dense :color="colors[type]" v-show="visible" transition="scale-transition">
        <div class=" d-flex align-center">
          <VIcon v-if="icons[type]" class="mr-3" :color="iconColor[type]">{{ icons[type] }}</VIcon>
          <div class=" white--text subtitle-1">
            {{ message }}
          </div>
          <VSpacer />
          <VIcon class="ml-3" color="white" @click="close"> {{ icons.mdiClose }}</VIcon>
        </div>
      </v-alert>
      <!-- <VCard v-show="visible" :color="colors[type]" class=" d-flex px-3 py-2 align-center mb-2" width="400"
        transition="scale-transition">
        <VIcon v-if="icons[type]" class="mr-3" :color="iconColor[type]">{{ icons[type] }}</VIcon>
        <div class=" white--text subtitle-1">
          {{ message }}
        </div>
        <VSpacer />
        <VIcon class="ml-3" color="white" @click="close"> {{ icons.mdiClose }}</VIcon>
      </VCard> -->
    </transition>
  </div>
</template>

<script>
import { mdiAlertCircleOutline,mdiCheckCircleOutline, mdiClose } from '@mdi/js'
//todo 动画处理
const typeMap = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
}
export default {
  name: 'MessageBox',
  data() {
    return {
      icons: {
        error: mdiAlertCircleOutline,
        success:mdiCheckCircleOutline,
        mdiClose
      },
      colors: {
        error: '#5F3030',
        success:'#81C784'
      },
      iconColor: {
        error: '#FF5454',
        success:'#388E3C'
      },
      visible: false,
      message: '',
      duration: 5000,
      type: 'info',
      iconClass: '',
      customClass: '',
      onClose: null,
      showClose: true,
      closed: false,
      verticalOffset: 70,
      timer: null,
      dangerouslyUseHTMLString: false,
      center: false,
      text: false,
    }
  },

  computed: {
    typeClass() {
      return this.type && !this.iconClass ? `el-message__icon el-icon-${typeMap[this.type]}` : ''
    },
    positionStyle() {
      return {
        top: `${this.verticalOffset}px`,
      }
    },
  },

  watch: {
    closed(newVal) {
      if (newVal) {
        this.visible = false
      }
    },
  },
  methods: {
    handleAfterLeave() {
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    },

    close() {
      this.closed = true
      if (typeof this.onClose === 'function') {
        this.onClose(this)
      }
    },

    clearTimer() {
      clearTimeout(this.timer)
    },

    startTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close()
          }
        }, this.duration)
      }
    },
    keydown(e) {
      if (e.keyCode === 27) {
        // esc关闭消息
        if (!this.closed) {
          this.close()
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.startTimer()
    })
  },
}
</script>

<style scoped lang="scss">
.message-view {
  max-width: 800px;
  position: fixed;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
}
</style>

<style>
.el-message-fade-enter,
.el-message-fade-leave-active {
  opacity: 0;
  transform: translateY(-100%);
}
</style>

<style lang="scss">
// .theme--dark {
//   .message-view {
//     .v-alert--text:before {
//       background-color: #28243d !important;
//       border-radius: 0;
//       z-index: -1 !important;
//       box-shadow: 0 0 4px rgba(114, 108, 108, 0.36) !important;
//       opacity: 1;
//     }
//   }
// }

// .theme--light {
//   .message-view {
//     .v-alert--text:before {
//       background-color: #ffffff !important;
//       border-radius: 0;
//       z-index: -1 !important;
//       box-shadow: 0 0 4px rgba(114, 108, 108, 0.36) !important;
//       opacity: 1;
//     }
//   }
// }
</style>
