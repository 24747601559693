import http from "../utils/http";

export function login(data) {
  return new http({
    url: "api/auth/login",
    method: "post",
    data,
  });
}

export function getActorList(params) {
  return new http({
    url: "api/role",
    method: "get",
    params,
  });
}

export function generateAiImage(data) {
  return new http({
    url: "api/role/style",
    method: "post",
    data,
  });
}

export function getActorImageList(params) {
  return new http({
    url: "api/role/style",
    method: "get",
    params,
  });
}
export function chooseImageList(data) {
  return new http({
    url: "api/role/style/choose",
    method: "put",
    data,
  });
}

export function getStockList(params) {
  return new http({
    url: "api/content/sub",
    method: "get",
    params,
  });
}

export function getStockDetail(params) {
  return new http({
    url: "api/content/sub/detail",
    method: "get",
    params,
  });
}
export function generateStockImage(data) {
  return new http({
    url: "api/content/sub/show",
    method: "post",
    data,
  });
}

export function generateStockImageResult(data) {
  return new http({
    url: "api/content/sub/show/result",
    method: "post",
    data,
  });
}

export function getStockImageList(params) {
  return new http({
    url: "api/content/sub/show",
    method: "get",
    params,
  });
}
export function chooseStockImage(data) {
  return new http({
    url: "api/content/sub/show/choose",
    method: "put",
    data,
  });
}

export function saveStockSync(data) {
  return new http({
    url: "api/project/save/sync",
    method: "post",
    data,
  });
}
export function saveStock(data) {
  return new http({
    url: "api/project/save",
    method: "post",
    data,
  });
}

export function getProject(params) {
  return new http({
    url: "api/project",
    method: "get",
    params,
  });
}

export function addStock(data) {
  return new http({
    url: "api/content/sub/add",
    method: "put",
    data,
  });
}
// export function insertStock() {}
// export function copyStock() {}
export function deleteStock(data) {
  return new http({
    url: "api/content/sub/del",
    method: "put",
    data,
  });
}

export function getDict(parentKey) {
  return new http({
    url: "api/dict/by/parent-key",
    method: "get",
    params: { parentKey },
  });
}
export function getStyle(params) {
  return new http({
    url: "api/project/style",
    method: "get",
    params,
  });
}
export function updateProject(data) {
  return new http({
    url: "api/project",
    method: "put",
    data,
  });
}
export function updateProjectResult(params) {
  return new http({
    url: "api/project/result",
    method: "get",
    params,
  });
}
// 重新分页
export function regenerate(data) {
  return new http({
    url: "api/content/sub/repage/sync",
    method: "put",
    data,
  });
}

export function regenerateResult(params) {
  return new http({
    url: "api/content/sub/repage/result",
    method: "get",
    params,
  });
}

// 视频生成结果查询
export function generateResult(params) {
  return new http({
    url: "api/project/generate/result",
    method: "get",
    params,
  });
}


// 视频生成结果查询
export function generateHint(data) {
  return new http({
    url: "api/content/sub/generate/prompt",
    method: "put",
    data,
  });
}

export function getProjectList(params) {
  return new http({
    url: "api/project/list",
    method: "get",
    params,
  });
}


export function rename(data) {
  return new http({
    url: "api/project/rename",
    method: "put",
    data,
  });
}

export function delProject(data) {
  return new http({
    url: "api/project/del",
    method: "put",
    data,
  });
}
export function regenerateProject(data) {
  return new http({
    url: "api/project/re/generate",
    method: "put",
    data,
  });
}
export function explain(data) {
  return new http({
    url: "api/project/tools/explain",
    method: "post",
    data,
  });
}
export function explainResult(params) {
  return new http({
    url: "api/project/tools/explain/result",
    method: "get",
    params,
  });
}
export function aiSplit(data) {
  return new http({
    url: "api/project/tools/split",
    method: "post",
    data,
  });
}
export function aiSplitResult(params) {
  return new http({
    url: "api/project/tools/split/result",
    method: "get",
    params,
  });
}

export function reDoing(data) {
  return new http({
    url: "api/project/re/doing",
    method: "put",
    data,
  });
}

export function logout(data) {
  return new http({
    url: "api/auth/logout",
    method: "post",
    data,
  });
}

export function getRoles(params) {
  return new http({
    url: "api/project/role",
    method: "get",
    params,
  });
}

export function generateRoles(data) {
  return new http({
    url: "api/project/tools/role",
    method: "post",
    data,
  });
}

export function updateRoles(data) {
  return new http({
    url: "api/role",
    method: "put",
    data,
  });
}
export function upload(data) {
  return new http({
    url: "api/project/tools/upload",
    method: "post",
    data,
  });
}
// 重绘图片
export function reDrawImg(data) {
  return new http({
    url: "api/content/sub/show/img",
    method: "post",
    data,
  });
}

// 重绘图片结果查询
export function reDrawImgResult(data) {
  return new http({
    url: "api/content/sub/show/img/result",
    method: "post",
    data,
  });
}


// 重绘图片结果查询
export function getReDrawList(params) {
  return new http({
    url: "api/content/sub/show/his",
    method: "get",
    params,
  });
}

// 重绘图片结果查询
export function reDrawChoose(data) {
  return new http({
    url: "api/content/sub/show/img/choose",
    method: "put",
    data,
  });
}
