<template>
  <div class="h-full d-flex flex-column vf-home overflow-hidden px-16">
    <div class="d-flex px-5 pt-2">
      <div class=" side-left pt-8 px-7 d-flex flex-column">
        <div class=" d-flex align-center cursor-pointer" @click="tab = 'tab-1'">
          <div>
            <img src="../assets/logo.png" style="height: 5rem;" alt="">
          </div>
          <div class="logo-font pa-2 mt-n1" style="font-weight: 600;">VeedioFlow</div>
          <div class="pl-5">
            <v-menu open-on-hover offset-x nudge-top="-10">
              <template v-slot:activator="{ on, attrs }">
                <v-img src="@/assets/avatar.png" class="" width="3.5rem" v-bind="attrs" v-on.stop.prevent="on"></v-img>
              </template>
              <v-list dense class=" caption">

                <v-list-item @click="logout">
                  <v-list-item-title>{{ $t('退出登录') }}</v-list-item-title>
                </v-list-item>

              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
      <VSpacer />
      <div class="pr-6 pt-9">
        <v-menu open-on-hover offset-x nudge-top="-10">
          <template v-slot:activator="{ on, attrs }">
            <div class="pr-4 mr-10" v-bind="attrs" v-on.stop.prevent="on">
              <v-img width="7.8rem" src="@/assets/message.png" content-class="d-flex justify-center align-center">
                <div class="pb-3 d-flex align-center">
                  <div class="font-18">
                    {{ $t('vfi18n.newVideo') }}
                  </div>
                  <svg-icon class="ml-2" iconName="video" size="1.25rem"></svg-icon>
                </div>
              </v-img>
            </div>
          </template>
          <v-list dense class=" caption">

            <v-list-item @click="start">
              <v-list-item-title>{{ $t('vfi18n.startCreation') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="text">
              <v-list-item-title>{{ $t('vfi18n.textToFilm') }}</v-list-item-title>


            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!-- <div class=" flex-grow-1 d-flex justify-end align-center pr-8">
        <VBtn color="primary">{{ $t('vfi18n.confirmVideoType') }} →</VBtn>
      </div> -->
    </div>
    <!-- <v-card> -->
    <div class="px-16 mt-n2">
      <v-tabs v-model="tab" background-color="#0b0c14" color="#695EE9">
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1" class=" subtitle-1 font-weight-bold px-12 py-4">
          {{ $t('vfi18n.video') }}

        </v-tab>

        <v-tab href="#tab-2" class="  subtitle-1 font-weight-bold  px-12  py-4">
          {{ $t('vfi18n.draft') }}

        </v-tab>


      </v-tabs>
      <VDivider />
    </div>
    <div class="px-16 flex-grow-1 overflow-y-auto">
      <v-tabs-items v-model="tab" class="item-bg ">
        <v-tab-item value="tab-1">
          <v-sheet class="pt-16" color="#0b0c14">
            <v-row class="ma-n6">
              <v-col v-for="item in doneList" cols="3" :key="item.id" class="pa-6 pt-0">
                <ProjectCard :item="item" @play="play(item)" @reload="reload"></ProjectCard>
              </v-col>
            </v-row>
          </v-sheet>
        </v-tab-item>
        <v-tab-item value="tab-2">

          <v-sheet class="pt-16" color="#0b0c14">
            <v-row class="ma-n6">
              <v-col v-for="item in draftList" cols="3" :key="item.id" class="pa-6 pt-0">
                <ProjectCard :item="item" @play="play(item)" @reload="reload"></ProjectCard>
              </v-col>
            </v-row>
          </v-sheet>

        </v-tab-item>
      </v-tabs-items>

      <!-- </v-card> -->
      <VDialog v-model="dialog" persistent transition="dialog-transition" max-width="50vw" no-click-animation
        overlay-color="#000000" overlay-opacity="0.85">
        <VCard class="overflow-y-hidden" v-if="dialog">
          <videoPlayer ref="player" :options="playerOptions">

          </videoPlayer>
          <!-- <div class=" position-absolute h-full w-full d-flex align-center justify-center cursor-pointer"
            style="left: 0;top: 0;z-index: 299999;" @click="playVideo">
            <VIcon size="6vw" color="white">{{ icons.mdiPlay }}</VIcon>
          </div> -->

        </VCard>

      </VDialog>
      <div v-if="dialog" class=" w-full d-flex align-center justify-center cursor-pointer"
        style="left: 0;bottom: 10vh;z-index: 299999; position: fixed;">
        <VIcon size="2vw" color="white" @click="dialog = false">{{ icons.mdiCloseCircle }}</VIcon>
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from "../components/HelloWorld";
import {
  getProjectList,
  logout
} from "@/api";

import ProjectCard from '@/components/ProjectCard/index.vue'
import 'video.js/dist/video-js.css'

import { videoPlayer } from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'

import { mdiPlay, mdiCloseCircle } from "@mdi/js";


export default {
  name: "HomeView",

  components: {
    ProjectCard,
    videoPlayer
  },
  data() {
    return {
      tab: null,
      icons: {
        mdiPlay,
        mdiCloseCircle
      },
      dialog: false,
      playerOptions: {
        // videojs options
        fluid: true, // 支持响应式布局
        autoplay: true, // 自动播放
        language: 'en', // 播放器语言
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 播放速率选项
        controls: true, // 是否显示控制条
        preload: 'auto', // 预加载方式
        crossOrigin: 'anonymous', // 跨域设置
        muted: false, // 是否静音
        loop: false, // 是否循环播放
        // aspectRatio: '9:16', // 播放器宽高比
        width: '640px', // 设置固定宽度
        bigPlayButton: true, // 是否显示大播放按钮
        loadingSpinner: true, // 是否显示加载中的旋转图标
        controlBar: {
          timeDivider: true, // 是否显示时间分隔符
          durationDisplay: true, // 是否显示视频总时长
          remainingTimeDisplay: true, // 是否显示剩余时间
          fullscreenToggle: true, // 是否显示全屏切换按钮
        },
        sources: [{
          type: "video/mp4",
          src: ''
        }],
        poster: '',
      },
      draftList: [],

      doneList: [
        {
          "id": "1",
          "vid": "1",
          "title": '',
          "desc": null,
          "type": null,
          "style": null,
          "speaker": null,
          "backMusic": null,
          "projectStatus": '',
          "projectProcess": null,
          "resultUrl": null,
          "showPicture": null
        },
        {
          "id": "2",
          "vid": "1",
          "title": '',
          "desc": null,
          "type": null,
          "style": null,
          "speaker": null,
          "backMusic": null,
          "projectStatus": '',
          "projectProcess": null,
          "resultUrl": null,
          "showPicture": null
        },
        {
          "id": "3",
          "vid": "1",
          "title": null,
          "desc": null,
          "type": null,
          "style": null,
          "speaker": null,
          "backMusic": null,
          "projectStatus": '',
          "projectProcess": null,
          "resultUrl": null,
          "showPicture": null
        },
        {
          "id": "4",
          "vid": "1",
          "title": null,
          "desc": null,
          "type": null,
          "style": null,
          "speaker": null,
          "backMusic": null,
          "projectStatus": '',
          "projectProcess": null,
          "resultUrl": null,
          "showPicture": null
        }
      ],
    }
  },
  mounted() {
    this.getProjectListDraft();
    this.getProjectListDone()
  },
  methods: {
    async logout(){
      await logout()
      this.getProjectListDraft();
    },
    async reload() {
      this.getProjectListDraft();
      this.getProjectListDone()
    },
    async getProjectListDraft() {
      const { data: { data } } = await getProjectList({ draft: true })
      this.draftList = data || []
    },
    async getProjectListDone() {
      this.$loading.show()
      const { data: { data } } = await getProjectList({ draft: false })
      this.doneList = data || []
      this.$loading.hide()
    },
    play(item) {
      console.log(item)
      this.playerOptions.sources[0].src = item.resultUrl
      this.playerOptions.poster = item.showPicture
      console.log(this.playerOptions)
      this.$nextTick(() => {
        this.dialog = true
      })

    },
    playVideo() {
      console.log(this.$refs.player)
      this.$refs.player.player.play()
    },
    start() {
      this.$router.push({ name: 'styleSelection' })
    },
    text() {
      this.$router.push({ name: 'textMontage' })
    }
  },
};
</script>
<style lang="scss" scoped>
.logo-font {
  font-size: 27px !important;
  font-weight: 600 !important;
  font-family: 'Outfit' !important;
}

.font-18 {
  font-size: 18px;
}

.vf-home {
  min-height: 100vh;
  max-height: 100vh;
}

.item-bg {
  background-color: #0b0c14;
}

.custom-video-player {
  width: 640px;
  /* 设置固定宽度 */
  height: auto;
  /* 让高度自适应 */
}

::v-deep .video-js .vjs-big-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: 0;
  zoom: 4;
}

.theme--dark.v-list-item:hover {
  background-color: #60c;

  &::before {
    opacity: 0.08;
  }
}
</style>