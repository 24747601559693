import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import "@/assets/fonts/font.css"
import "./utils/rootFont";
import "@/icons"; // icon

import VnTitle from "./components/VueNova/VnTitle";


import Message from "./components/Message";
import Loading from "./components/loading";
import Confirm from "./components/Confirm";
import VueDragscroll from "vue-dragscroll";
Vue.use(VueDragscroll);
// 全局注册组件
Vue.component("vn-title", VnTitle);

import "@/styles/style.scss";

//mock
import "@/mock/mockService.js";

Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading;
Vue.prototype.$confirm = Confirm;

Vue.config.productionTip = true;

new Vue({
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
