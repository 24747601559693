<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <v-card data-no-dragscroll class="rounded-xxl flex-shrink-0 stock-card" outlined @click="editDraft(item)">
      <v-img data-no-dragscroll width="100%" height="100%" class="rounded-xxl grey darken-4" :aspect-ratio="aspectRatio"
        :src="item.showPicture" contain>
        <div class="h-full item" :class="{ 'mask': ['WAIT', 'GENERATE'].includes(item.projectStatus) }"
          data-no-dragscroll></div>
      </v-img>
      <v-sheet v-if="['WAIT', 'GENERATE', 'FAIL'].includes(item.projectStatus)"
        :color="item.projectStatus == 'WAIT' || item.projectStatus == 'GENERATE' ? 'primary' : '#C94629'"
        class="white--text stock-number caption  d-flex justify-center align-center rounded-tr-0 rounded-bl-0 rounded-tl-xxl rounded-br-xxl px-4 py-2">{{
          item.projectStatus == 'WAIT' ? `${$t('vfi18n.wait')}` : item.projectStatus == 'GENERATE' ?
          `${$t('vfi18n.synthesizing')}-${item.projectProcess}%` : $t('vfi18n.synthesisFailed') }}</v-sheet>
      <div v-if="['DONE'].includes(item.projectStatus)"
        class="paly-wrap position-absolute w-full h-full d-flex justify-center align-center" style="left:0;top:0">
        <v-icon size="80" @click="play">{{ icons.mdiPlayCircleOutline }}</v-icon>
      </div>
      <v-menu offset-y open-on-hover v-if="['DOING', 'FAIL', 'DONE'].includes(item.projectStatus)">
        <template v-slot:activator="{ on, attrs }">

          <v-sheet class="position-absolute rounded-lg  d-flex justify-center align-center stock-more" width="1.4rem"
            height="1.4rem" v-bind="attrs" v-on.stop.prevent="on">
            <VIcon size="1rem">{{ icons.mdiDotsHorizontal }}</VIcon>
          </v-sheet>
        </template>
        <v-list dense class=" caption" min-width="7rem">
          <v-list-item @click="download(item)" v-if="['DONE'].includes(item.projectStatus)">
            <v-list-item-title>{{ $t('vfi18n.download') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="edit(item)" v-if="['DONE', 'FAIL'].includes(item.projectStatus)">
            <v-list-item-title>{{ $t('vfi18n.reEdit') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="regenerate(item)" v-if="['FAIL'].includes(item.projectStatus)">
            <v-list-item-title>{{ $t('vfi18n.reSynthesize') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleEditTitle(item)">
            <v-list-item-title>{{ $t('vfi18n.rename') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="del(item)">
            <v-list-item-title>{{ $t('vfi18n.delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card>
    <!-- <v-text-field v-model="item.title" hide-details dense></v-text-field> -->
    <div v-if="!editTitleFlag" class=" d-flex mt-2 pb-1">
      <div class="mr-8">{{ item.title }} </div>
      <!-- <VIcon size="1.6rem" color="#A4A4B0" @click="handleEditTitle">{{ icons.mdiPencil }}</VIcon> -->
    </div>
    <div class="title-wrap mt-1" v-else>
      <VTextField ref="stockTitle" v-model="name" autofocus class="pa-0 ma-0 theme-title" hide-details=""
        :maxlength="titleLen" @blur="handleDoneEdit" @keyup.esc="cancelEdit" @keyup.enter="handleDoneEdit" />
    </div>
  </div>
</template>

<script>
import { mdiDotsHorizontal, mdiPlayCircleOutline } from '@mdi/js'
import { rename, delProject, regenerateProject, reDoing } from '@/api/index.js'
export default {
  name: 'ProjectCard',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      name: '',
      icons: { mdiDotsHorizontal, mdiPlayCircleOutline },
      editTitleFlag: false,
      aspectRatio: 16 / 9
    }
  },
  computed: {
    isEn() {
      return this.$i18n.locale == 'en'
    },
    titleLen() {
      return this.isEn ? 40 : 20
    }
  },
  methods: {
    download() {
      const link = document.createElement('a');
      link.href = this.item.resultUrl;
      // link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async edit() {
      if (['FAIL'].includes(this.item.projectStatus)) {
        // this.$loading.show(100, this.$t('vfi18n.processing'))
        const { data } = await reDoing({ pid: this.item.id })
        // this.$loading.hide()
        if (data.code != 0) {
          this.$message.error(data.msg)
          return
        }
      }
      this.$router.push({
        name: 'generateVideo', query: {
          pid: this.item.id
        }
      })
    },
    editDraft() {
      if (['DOING'].includes(this.item.projectStatus)) {
        this.edit()
      }
    },

    async del() {
      await this.$confirm({
        title: this.$t('确定要删除该项目吗？'),
        subTitle: this.$t('项目删除后不可恢复，请谨慎操作')
      })
      this.$loading.show()
      try {
        await delProject({ pid: this.item.id })
        this.$loading.hide()
      } catch (error) {
        this.$loading.hide()
      }
      this.$emit('reload')

    },
    async regenerate() {
      this.$loading.show(100, '处理中')
      await regenerateProject({ pid: this.item.id })
      this.$loading.hide()
      this.$emit('reload')
    },
    play() {
      this.$emit('play')
    },
    handleEditTitle() {
      this.name = this.item.title
      this.editTitleFlag = true
      this.$nextTick(() => {
        // this.$refs.stockTitle.select();
        window.stockTitle = this.$refs.stockTitle
        // 获取文本框的DOM元素
        const textField = this.$refs.stockTitle.$el.querySelector("input");

        // 选择文本
        textField.select();
      })
    },
    async handleDoneEdit() {

      if (!this.name) {
        // eslint-disable-next-line vue/no-mutating-props
        this.item.title = '无标题'
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.item.title = this.name
      }


      rename({
        "pid": this.item.id,
        "name": this.item.title
      })
      this.editTitleFlag = false
    },
    cancelEdit() {
      this.editTitleFlag = false
    },
  },

}
</script>

<style lang="scss" scoped>
.stock-card {
  .mask {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))
  }

  .stock-number {
    position: absolute;
    top: -2px;
    left: -2px;
  }

  .stock-more {
    display: none !important;
    right: 0.6rem;
    top: 0.4rem;
    display: none;

    &:hover {
      background-color: #60c;
    }
  }

  .paly-wrap {
    display: none !important;
  }

  &:hover {
    .stock-more {
      display: flex !important;
    }

    .paly-wrap {
      display: flex !important;
    }
  }
}

.stock-card.primary {
  border-width: 3px;

  .stock-number {

    top: -3px;
    left: -3px;
  }

}

.theme--dark.v-list-item:hover {
  background-color: #60c;

  &::before {
    opacity: 0.08;
  }
}

.title-wrap {
  ::v-deep input::selection {
    background-color: #60c;
  }
}
</style>